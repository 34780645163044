var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-card', {
    staticStyle: {
      "overflow": "auto"
    }
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Judul",
      "label-for": "Judul"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "Judul",
      "type": "text",
      "placeholder": "Masukkan Judul"
    },
    model: {
      value: _vm.form.judul,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "judul", $$v);
      },
      expression: "form.judul"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Kategori Paket",
      "label-for": "paket"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "clearable": true,
      "options": _vm.optpaket_id,
      "placeholder": "Pilih Kategori Paket"
    },
    model: {
      value: _vm.form.paket_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "paket_id", $$v);
      },
      expression: "form.paket_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Paket",
      "label-for": "paket"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "clearable": true,
      "options": _vm.optpaket2_id,
      "placeholder": "Pilih Paket"
    },
    model: {
      value: _vm.form.paket2_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "paket2_id", $$v);
      },
      expression: "form.paket2_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal & Waktu Mulai",
      "label-for": "start_time"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true
      },
      "placeholder": "Tentukan Jadwal Mulai"
    },
    model: {
      value: _vm.form.start_time,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "start_time", $$v);
      },
      expression: "form.start_time"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal & Waktu Akhir",
      "label-for": "end_time"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true
      },
      "placeholder": "Tentukan Jadwal Akhir"
    },
    model: {
      value: _vm.form.end_time,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "end_time", $$v);
      },
      expression: "form.end_time"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Atur Tanggal di masing-masing ujian",
      "label-for": "atur"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.is_date_in_subject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_date_in_subject", $$v);
      },
      expression: "form.is_date_in_subject"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    attrs: {
      "align": "right"
    }
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-tambah-sub",
      "centered": "",
      "size": "xl",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.TambahSub($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.tutupModal($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.ModalTambahMapel,
      callback: function callback($$v) {
        _vm.ModalTambahMapel = $$v;
      },
      expression: "ModalTambahMapel"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-row', [_vm.form.paket2_id != null && _vm.form.paket2_id.is_contain_subcat == 1 ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sub Kategori",
      "label-for": "Subtest"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "Subtest",
      "options": _vm.paketSubtestDataParent,
      "placeholder": "Sub Kategori"
    },
    model: {
      value: _vm.formSub.subcat_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formSub, "subcat_id", $$v);
      },
      expression: "formSub.subcat_id"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Ujian",
      "label-for": "nama-paket"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nama Ujian",
      "name": "nama-paket"
    },
    model: {
      value: _vm.formSub.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formSub, "name", $$v);
      },
      expression: "formSub.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Ujian (singkat)",
      "label-for": "harga"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "shortname",
      "placeholder": "shortname"
    },
    model: {
      value: _vm.formSub.shortname,
      callback: function callback($$v) {
        _vm.$set(_vm.formSub, "shortname", $$v);
      },
      expression: "formSub.shortname"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Acak Soal",
      "label-for": "acak"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optquestion
    },
    model: {
      value: _vm.formSub.shuffle_question,
      callback: function callback($$v) {
        _vm.$set(_vm.formSub, "shuffle_question", $$v);
      },
      expression: "formSub.shuffle_question"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tampilkan Hasil",
      "label-for": "show_result"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optresult
    },
    model: {
      value: _vm.formSub.show_result,
      callback: function callback($$v) {
        _vm.$set(_vm.formSub, "show_result", $$v);
      },
      expression: "formSub.show_result"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Instruksi",
      "label-for": "instruksi"
    }
  }, [_c('quill-editor', {
    attrs: {
      "id": "Konten",
      "options": _vm.editor_options
    },
    model: {
      value: _vm.formSub.instruction,
      callback: function callback($$v) {
        _vm.$set(_vm.formSub, "instruction", $$v);
      },
      expression: "formSub.instruction"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "responsive": "sm",
      "fields": _vm.form.is_date_in_subject == false || _vm.form.is_date_in_subject == 0 ? _vm.fields : _vm.fields2,
      "items": _vm.ujiandata
    },
    scopedSlots: _vm._u([{
      key: "head(index)",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          on: {
            "change": _vm.selectAll
          },
          model: {
            value: _vm.allSelected,
            callback: function callback($$v) {
              _vm.allSelected = $$v;
            },
            expression: "allSelected"
          }
        })];
      }
    }, {
      key: "cell(index)",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": data.item
          },
          model: {
            value: _vm.select_ujian,
            callback: function callback($$v) {
              _vm.select_ujian = $$v;
            },
            expression: "select_ujian"
          }
        })];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1)], 1)];
      }
    }, {
      key: "cell(start_time)",
      fn: function fn(data) {
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "config": {
              enableTime: true,
              dateFormat: 'Y-m-d H:i:S',
              time_24hr: true
            }
          },
          model: {
            value: data.item.start_time,
            callback: function callback($$v) {
              _vm.$set(data.item, "start_time", $$v);
            },
            expression: "data.item.start_time"
          }
        })];
      }
    }, {
      key: "cell(end_time)",
      fn: function fn(data) {
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "config": {
              enableTime: true,
              dateFormat: 'Y-m-d H:i:S',
              time_24hr: true
            }
          },
          model: {
            value: data.item.end_time,
            callback: function callback($$v) {
              _vm.$set(data.item, "end_time", $$v);
            },
            expression: "data.item.end_time"
          }
        })];
      }
    }, {
      key: "cell(timer)",
      fn: function fn(data) {
        return [_c('b-form-input', {
          attrs: {
            "size": "sm",
            "placeholder": "timer",
            "type": "number"
          },
          model: {
            value: data.item.timer,
            callback: function callback($$v) {
              _vm.$set(data.item, "timer", $$v);
            },
            expression: "data.item.timer"
          }
        })];
      }
    }, {
      key: "cell(passing_grade)",
      fn: function fn(data) {
        return [_c('b-form-input', {
          attrs: {
            "size": "sm",
            "placeholder": "passing_grade",
            "type": "number"
          },
          model: {
            value: data.item.passing_grade,
            callback: function callback($$v) {
              _vm.$set(data.item, "passing_grade", $$v);
            },
            expression: "data.item.passing_grade"
          }
        })];
      }
    }, {
      key: "cell(sum_soal)",
      fn: function fn(data) {
        return [_c('b-form-input', {
          attrs: {
            "value": data.item.sum_soal,
            "size": "sm",
            "placeholder": "0",
            "type": "number",
            "disabled": ""
          }
        })];
      }
    }]),
    model: {
      value: _vm.ujians,
      callback: function callback($$v) {
        _vm.ujians = $$v;
      },
      expression: "ujians"
    }
  }), !_vm.form.paket2_id ? _c('section', {
    staticClass: "my-1"
  }, [_c('b-alert', {
    staticClass: "p-1",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v(" Silahkan pilih paket terlebih dahulu untuk memunculkan daftar ujian. ")])])], 1) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": "false",
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  }, [_vm._v(" Aktifkan Jadwal (agar bisa diakses siswa) ")]), _c('br'), _c('b-form-checkbox', {
    staticClass: "mt-1",
    attrs: {
      "checked": "false",
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.file_pembahasan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "file_pembahasan", $$v);
      },
      expression: "form.file_pembahasan"
    }
  }, [_vm._v(" Pembahasan & Kunci Jawaban ")])], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jadwal diperuntukkan untuk?",
      "label-for": "jadwal"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "radio-group-2",
      "name": "radio-sub-component"
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "member"
    }
  }, [_vm._v("Member")]), _c('b-form-radio', {
    attrs: {
      "value": "non-member"
    }
  }, [_vm._v("Non Member")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mt-1",
    attrs: {
      "label": "Maksimal Kuota",
      "label-for": "Maksimal Kuota"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "max_quota"
    },
    model: {
      value: _vm.form.max_quota,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "max_quota", $$v);
      },
      expression: "form.max_quota"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "Maksimal Kuota"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold mt-1"
  }, [_vm._v("Upload file format: jpg, png, pdf, doc, mp3, mp4 (opsional)")]), _c('b-form-file', {
    attrs: {
      "id": "Thumbnail",
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here...",
      "no-drop": "",
      "accept": ".jpg, .png, .pdf, .doc, .mp3, .mp4"
    },
    model: {
      value: _vm.form.file,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "file", $$v);
      },
      expression: "form.file"
    }
  })], 1)], 1)], 1)], 1), _c('b-button', {
    staticClass: "w-full mt-3",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.Tambah($event);
      }
    }
  }, [_vm._v(" Simpan ")]), _c('b-button', {
    staticClass: "w-full mt-3 mx-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearForm($event);
      }
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }